<template>

  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">环保问题管理</a></li>
            <li class="breadcrumb-item active" id="title3">环保问题列表</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">环保问题列表</h4>
      </div>
    </div>
    <el-col  :span="24"  style="padding-bottom: 0px; margin-left: 10px">
<el-form :inline="true" :model="filters" >
<el-form-item>
<el-input v-model="filters.etitle" placeholder="问题标题"  size="small"></el-input>
</el-form-item>
<el-form-item>
<el-button type="primary" size="small" @click="query" icon="el-icon-search">搜索</el-button>
</el-form-item>
 </el-form>
</el-col>

<el-table :data="datalist" border stripe style="width: 100%"  v-loading="listLoading"   highlight-current-row   max-height="600"     size="small">
<el-table-column prop="etitle" label="问题标题"  align="center"></el-table-column>
<el-table-column prop="eimage" label="问题图片" width="70" align="center">
<template #default="scope">
<img :src="'https://www.jiuyihuishou.online/useclothingrecycling/' +scope.row.eimage" style="width: 50px;height: 50px" />
</template>
</el-table-column>
<el-table-column prop="etime" label="发布时间"  align="center"></el-table-column>
<el-table-column label="操作" min-width="200" align="center">
<template #default="scope">
<el-button type="primary" size="mini" @click="handleShow(scope.$index, scope.row)" icon="el-icon-zoom-in" style=" padding: 3px 6px 3px 6px;">详情</el-button>
<el-button type="success" size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit" style=" padding: 3px 6px 3px 6px;">编辑</el-button>
<el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)" icon="el-icon-delete" style=" padding: 3px 6px 3px 6px;">删除</el-button>
</template>
</el-table-column>
</el-table>
<el-pagination  @current-change="handleCurrentChange" :current-page="page.currentPage" :page-size="page.pageSize" 
 background layout="total, prev, pager, next, jumper" :total="page.totalCount" 
 style="float: right; margin: 10px 20px 0 0"></el-pagination>

  </div>

</template>
<script>
import request, { base } from "../../../utils/http";
export default {
  name: 'environment',
  components: {
    
  },  
    data() {
      return {
               filters: {
          //列表查询参数
          etitle: '',
        },

        page: {
          currentPage: 1, // 当前页
          pageSize: 10, // 每页显示条目个数
          totalCount: 0, // 总条目数
        },
        isClear: false,      
        
        listLoading: false, //列表加载状态
        btnLoading: false, //保存按钮加载状态
        datalist: [], //表格数据  
    
      };
    },
    created() {
      this.getDatas();
    },

 
    methods: {    

              
       // 删除环保问题
        handleDelete(index, row) {
          this.$confirm("确认删除该记录吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.listLoading = true;
              let url = base + "/environment/del?id=" + row.eid;
              request.post(url).then((res) => {
                this.listLoading = false;
             
                this.$message({
                  message: "删除成功",
                  type: "success",
                  offset: 320,
                });
                this.getDatas();
              });
            })
            .catch(() => { });
        },
                
        // 分页
        handleCurrentChange(val) {
          this.page.currentPage = val;
          this.getDatas();
        },     
     
        //获取列表数据
        getDatas() {      
          let para = {
               etitle:this.filters.etitle,

          };
          this.listLoading = true;
          let url = base + "/environment/list?currentPage=" + this.page.currentPage+ "&pageSize=" + this.page.pageSize;        
          request.post(url, para).then((res) => {   
            if (res.resdata.length > 0) {
              this.isPage = true;
            } else {
              this.isPage = false;
            }
            this.page.totalCount = res.count;
            this.datalist = res.resdata;
            this.listLoading = false;
          });
        },    
                 //查询
        query() {
          this.getDatas();
        },  
           
        // 查看
        handleShow(index, row) {
          this.$router.push({
            path: "/EnvironmentDetail",
             query: {
                id: row.eid,
              },
          });
        },
    
        // 编辑
        handleEdit(index, row) {
          this.$router.push({
            path: "/EnvironmentEdit",
             query: {
                id: row.eid,
              },
          });
        },
      },
}

</script>
<style scoped>
</style>
 

